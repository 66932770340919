<template>
  <div class="container">
    <div class="houses__wrapper screen__wrapper">
      <Card
        v-for="house in houses"
        :key="house.id"
        :obj="house"
        @click.native="info(house)"
      />
    </div>
    <infinite-loading v-if="!isLoading && hasMoreResults" @infinite="loadObj"></infinite-loading>
  </div>
</template>

<script>
import Card from '@/views/Objects/Card'
import InfiniteLoading from 'vue-infinite-loading'
import InfoObject from '@/views/Objects/Info'

export default {
  name: "FavoriteObjectsHouses",
  components: {
    Card,
    InfiniteLoading
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    houses() {
      return this.$store.getters.objectsFavHouse;
    },
    hasMoreResults () { 
      return this.houses.length < this.totalCount && this.page > 1
    },
    totalCount() {
      return this.$store.getters.paginationFavObjectsHouse.total
    },
    page() {
      return this.$store.getters.pageFavObjectsHouse
    }
  },
  methods: {
    async loadObj() {
      this.isLoading = true
      const page = this.page
      try {
        await this.$store.dispatch('fetchFavoritesObjects', 'house')
        this.$emit('loaded')
      } finally {
        this.isLoading = false
      }
    },
    info(obj) {
      this.$modal.show(
        InfoObject,
        {info: obj}, 
        {name: 'InfoObject', transition: 'none', classes: 'modal', width: '900px', height: '92%'},
        {'before-open': this.$modal.open,'before-close': this.$modal.close}
      );
    }
  },
  // beforeDestroy() {
  //   if (Object.keys(this.$store.getters.sortParams).length !== 0 || Object.keys(this.$store.getters.filterParams).length !== 0) {
  //     this.$store.commit('sortParams', {})
  //     this.$store.commit('filterParams', {})
  //     this.$store.commit('resetFavObjects', 'house')
  //     this.loadObj()
  //   }
  // },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "./style.scss";
</style>


